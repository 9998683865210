@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  svg {
    vertical-align: unset;
  }
}

ul,
ol {
  list-style: revert;
  margin: revert;
  padding: revert;
}
